import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import {BaseService} from './base.service';
import {EntitiesService} from './entities.service';
import {BroadcastChannelService} from '@services/broadcast-channel.service';
import {environment} from '../../environments/environment';
import {VideoTabTypes} from '@models/video';
import {createThunderWebSocket, ThunderWebSocket} from '@models/thunder-web-socket';
import {VideoMetrics} from '@models/constants/video/video-metric-types';
import * as _ from 'lodash';
import 'rxjs-compat/add/observable/of';
import {VideoTables} from "@models/constants/video/video-action-items";
import {synergyActionNameMapper, videoActionToGroupName} from "@models/constants/video/video-subjects";
import {bildIDToLeagueID} from "@models/constants/leagues";

@Injectable()
export class VideoService extends BaseService {
  activeFilterSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  activePostVideoSubject: Subject<any> = new Subject<any>();
  videoAvailability: any = {};
  videoAvailabilitySubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  entityNBALookup: any = {};
  entityNBALookupSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  entitySportradarLookup: any = {};
  entitySportradarLookupSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  entitySynergyLookup: any = {};
  entitySynergyLookupSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sportradarSynergyGameMap: any = {};
  sportradarSynergyGameMapSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  hideFilterPanelSubject: Subject<boolean> = new Subject<boolean>();
  savedVideoListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  savedPlaylistsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  savedFoldersSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  selectedPlaylistSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedFolderSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedTabSubject: BehaviorSubject<VideoTabTypes> = new BehaviorSubject<VideoTabTypes>(null);
  videoTagLookupSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  videoDialogOpenedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  folderSource = new BehaviorSubject<any>(null);
  folderPath = new BehaviorSubject<any>(null);
  savedVideoListRequestProcessing: boolean = false;
  videoSocket: ThunderWebSocket;

  readonly NCAA_ID = 101;
  readonly INTL_ID = 102;
  readonly CATCH_AND_SHOOT_PARAMETERS = ['C&S Guarded', 'C&S Open', 'C&S Other'];

  constructor(protected http: HttpClient, protected broadcastChannelService: BroadcastChannelService, protected entitiesService: EntitiesService) {
    super(http, broadcastChannelService);
  }

  getAvailableGames(leagueIDs?, seasons?, gameTypes?, minDate?, maxDate?, teamEntityIDs?, offensiveTeamIDs?, defensiveTeamIDs?,
      onCourtEntities?, offCourtEntities?, offensiveLineupEntities?, defensiveLineupEntities?, primaryDefensivePlayer?,
      primaryEntity?, secondaryEntity?, abstractEntity?): Observable<any[]> {
    const endpoint = `${this.baseUrl}/videoGameAvailability`;
    let params: HttpParams = new HttpParams();

    if (leagueIDs) {
      params = params.set('leagueIDs', leagueIDs.join(','));
    }

    if (seasons) {
      params = params.set('seasons', seasons.join(','));
    }

    if (gameTypes) {
      params = params.set('gameTypes', gameTypes.join(','));
    }

    if (minDate) {
      params = params.set('minDate', minDate);
    }

    if (maxDate) {
      params = params.set('maxDate', maxDate);
    }

    if (teamEntityIDs) {
      params = params.set('teamEntityIDs', teamEntityIDs);
    }

    if (offensiveTeamIDs) {
      params = params.set('offensiveTeamIDs', offensiveTeamIDs);
    }

    if (defensiveTeamIDs) {
      params = params.set('defensiveTeamIDs', defensiveTeamIDs);
    }

    if (onCourtEntities) {
      params = params.set('onCourtEntities', onCourtEntities);
    }

    if (offCourtEntities) {
      params = params.set('offCourtEntities', offCourtEntities);
    }

    if (offensiveLineupEntities) {
      params = params.set('offensiveLineupEntities', offensiveLineupEntities);
    }

    if (defensiveLineupEntities) {
      params = params.set('defensiveLineupEntities', defensiveLineupEntities);
    }

    if (primaryDefensivePlayer) {
      params = params.set('primaryDefensivePlayer', primaryDefensivePlayer);
    }

    if (primaryEntity) {
      params = params.set('primaryEntity', primaryEntity);
    }

    if (secondaryEntity) {
      params = params.set('secondaryEntity', secondaryEntity);
    }

    if (abstractEntity) {
      params = params.set('abstractEntity', abstractEntity);
    }

    return this.get(endpoint, params).pipe(map(
        (data) => {
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  getCTGVideo(league, gameID, period, endPadding, startPadding, startTimestamp, startGameclock, smoothWithUrl?): Observable<any[]> {
    const endpoint = `${this.baseUrl}/CTGVideo`;
    let params: HttpParams = new HttpParams();

    if (league) {
      params = params.set('league', league);
    }

    if (gameID) {
      params = params.set('gameID', gameID);
    }

    if (period) {
      params = params.set('period', period);
    }

    if (endPadding) {
      params = params.set('endPadding', endPadding);
    }

    if (startPadding) {
      params = params.set('startPadding', startPadding);
    }

    if (startTimestamp) {
      params = params.set('startTimestamp', startTimestamp);
    }

    if (startGameclock) {
      params = params.set('startGameclock', startGameclock);
    }

    if (smoothWithUrl) {
      params = params.set('smoothWithUrl', smoothWithUrl);
    }

    return this.get(endpoint, params).pipe(map(
        (data) => {
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  getZelusData(league, gameID, endPadding, startPadding, startTimestamp): Observable<any[]> {
    const endpoint = `${this.baseUrl}/zelusData`;
    let params: HttpParams = new HttpParams();

    if (league) {
      params = params.set('league', league);
    }

    if (gameID) {
      params = params.set('gameID', gameID);
    }

    if (endPadding) {
      params = params.set('endPadding', endPadding);
    }

    if (startPadding) {
      params = params.set('startPadding', startPadding);
    }

    if (startTimestamp) {
      params = params.set('startTimestamp', startTimestamp);
    }

    return this.get(endpoint, params).pipe(map(
        (data) => {
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  createCTGEmailDownloadUrl(payload) {
    const endpoint = `${this.baseUrl}/CTGEmailDownloadUrl`;
    return this.post(endpoint, payload).pipe(map(
        (data) => {
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  getVideoAvailability() {
    const endpoint = `${this.baseUrl}/videoAvailability`;

    return this.get(endpoint).pipe(map(
        (data) => {
          this.videoAvailability = data;
          this.videoAvailabilitySubject.next(data);
          return this.videoAvailability;
        },
        (error) => {
          return error;
        },
    ));
  }

  getEntityNBALookup() {
    const endpoint = `${this.baseUrl}/entities/NBALookup`;

    return this.get(endpoint).pipe(map(
        (data) => {
          this.entityNBALookup = data;
          this.entityNBALookupSubject.next(data);
          return this.entityNBALookup;
        },
        (error) => {
          return error;
        },
    ));
  }

  getEntitySportradarLookup() {
    const endpoint = `${this.baseUrl}/entities/SportradarLookup`;

    return this.get(endpoint).pipe(map(
        (data) => {
          this.entitySportradarLookup = data;
          this.entitySportradarLookupSubject.next(data);
          return this.entitySportradarLookup;
        },
        (error) => {
          return error;
        },
    ));
  }

  getEntitySynergyLookup() {
    const endpoint = `${this.baseUrl}/entities/SynergyLookup`;

    return this.get(endpoint).pipe(map(
        (data) => {
          this.entitySynergyLookup = data;
          this.entitySynergyLookupSubject.next(data);
          return this.entitySynergyLookup;
        },
        (error) => {
          return error;
        },
    ));
  }

  getSportradarSynergyGameMap() {
    const endpoint = `${this.baseUrl}/sportradarSynergyGameMap`;

    return this.get(endpoint).pipe(map(
        (data) => {
          this.sportradarSynergyGameMap = data;
          this.sportradarSynergyGameMapSubject.next(data);
          return this.sportradarSynergyGameMap;
        },
        (error) => {
          return error;
        },
    ));
  }

  saveVideo(savedVideoData, savedVideoID?) {
    let endpoint: string;
    let method: any;

    let videoID = savedVideoID;

    if (!videoID && savedVideoData?.length === 1) {
      videoID = savedVideoData[0].id;
    }

    if (videoID) {
      endpoint = `${this.baseUrl}/savedVideo/${videoID}`;
      method = this.put.bind(this);
    } else {
      endpoint = `${this.baseUrl}/savedVideos`;
      method = this.post.bind(this);
    }

    return method(endpoint, savedVideoData);
  }

  getSavedVideos() {
    const endpoint = `${this.baseUrl}/savedVideos`;

    return this.get(endpoint).pipe(map(
        (data) => {
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  getSavedPlaylists() {
    const endpoint = `${this.baseUrl}/savedPlaylists`;

    return this.get(endpoint).pipe(map(
        (data) => {
          this.savedPlaylistsSubject.next(data);
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  getSavedVideoClips() {
    const endpoint = `${this.baseUrl}/savedVideoList`;

    this.savedVideoListRequestProcessing = true;
    return this.get(endpoint).pipe(map(
        (data) => {
          this.savedVideoListSubject.next(data);
          this.savedVideoListRequestProcessing = false;
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  deleteSavedVideos(savedVideoIDs) {
    const endpoint = `${this.baseUrl}/savedVideos`;
    return this.put(endpoint, savedVideoIDs).pipe(map(
        (data) => {
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  createSavedVideoToPost(name, date, homeTeam, awayTeam, period, startGameclock, startPadding, endPadding) {
    const endpoint = `${this.baseUrl}/CTGVideo`;
    const payload = {
      'name': name,
      'date': date,
      'homeTeam': homeTeam,
      'awayTeam': awayTeam,
      'period': period,
      'startGameclock': startGameclock,
      'startPadding': startPadding,
      'endPadding': endPadding,
    };

    return this.post(endpoint, payload).pipe(map(
        (data) => {
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  savePlaylist(savedPlaylistData) {
    let endpoint: string;
    let method: any;

    if (savedPlaylistData.id) {
      endpoint = `${this.baseUrl}/savedPlaylist/${savedPlaylistData.id}`;
      method = this.put.bind(this);
    } else {
      endpoint = `${this.baseUrl}/savedPlaylists`;
      method = this.post.bind(this);
    }

    return method(endpoint, savedPlaylistData);
  }

  deletePlaylist(savedPlaylistData) {
    const endpoint = `${this.baseUrl}/savedPlaylist/${savedPlaylistData.id}`;
    const method = this.delete.bind(this);

    return method(endpoint, savedPlaylistData);
  }

  getVideoGameMetadata(league, gameID) {
    const endpoint = `${this.baseUrl}/CTGVideo/metadata`;
    let params = new HttpParams();

    if (league) {
      params = params.set('league', league);
    }
    if (gameID) {
      params = params.set('gameID', gameID);
    }

    return this.get(endpoint, params).pipe(map(
        (data) => {
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  getVideoDiagnosticsData() {
    const endpoint = `${this.baseUrl}/CTGVideoDiagnostics`;

    return this.get(endpoint).pipe(map(
        (data) => {
          console.log(data);
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  reportCTGVideo(league, gameID, period, endPadding, startPadding, startTimestamp, startGameclock, feedback) {
    const endpoint = `${this.baseUrl}/CTGFlagClip`;
    const payload = {
      'league': league,
      'gameID': gameID,
      'period': period,
      'endPadding': endPadding,
      'startPadding': startPadding,
      'startTimestamp': startTimestamp,
      'startGameclock': startGameclock,
      'feedback': feedback,
    };

    return this.post(endpoint, payload).pipe(map(
        (data) => {
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  getVideoTagDefinitions() {
    const endpoint = `${this.baseUrl}/videoTagLookups`;

    return this.get(endpoint).pipe(map(
        (data) => {
          this.videoTagLookupSubject.next(data);
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  getVideoTags(aboutID?: number, tagLookupIDs?: number[], playerLookupIDs?: number[], minDate?: string, maxDate?: string, authorIDs?: string[]) {
    const endpoint = `${this.baseUrl}/videoTags`;
    let params: HttpParams = new HttpParams();

    if (aboutID) {
      params = params.set('aboutID', String(aboutID));
    }
    if (tagLookupIDs && tagLookupIDs.length) {
      params = params.set('tagLookupIDs', String(tagLookupIDs));
    }
    if (playerLookupIDs && playerLookupIDs.length) {
      params = params.set('playerLookupIDs', String(playerLookupIDs));
    }
    if (minDate) {
      params = params.set('minDate', minDate);
    }
    if (maxDate) {
      params = params.set('maxDate', maxDate);
    }
    if (authorIDs && authorIDs.length) {
      params = params.set('authorIDs', String(authorIDs));
    }

    return this.get(endpoint, params).pipe(map(
        (data) => {
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  saveVideoTag(videoTagData, videoTagID?) {
    let endpoint: string;
    let method: any;

    if (videoTagID) {
      endpoint = `${this.baseUrl}/videoTag/${videoTagID}`;
      method = this.put.bind(this);
    } else {
      endpoint = `${this.baseUrl}/videoTags`;
      method = this.post.bind(this);
    }

    return method(endpoint, videoTagData);
  }

  deleteVideoTag(videoTagID) {
    let endpoint: string;
    let method: any;

    endpoint = `${this.baseUrl}/videoTag/${videoTagID}`;
    method = this.delete.bind(this);

    return method(endpoint);
  }

  getCourtCamStreamUrls(startTimestamp?, endTimestamp?, includeThunder?, includeBlue?) {
    const endpoint = `${this.baseUrl}/CourtCamStreamUrls`;
    let params: HttpParams = new HttpParams();

    if (startTimestamp) {
      params = params.set('startTimestamp', startTimestamp);
    }

    if (endTimestamp) {
      params = params.set('endTimestamp', endTimestamp);
    }

    if (includeThunder != null) {
      params = params.set('includeThunder', includeThunder);
    }

    if (includeBlue != null) {
      params = params.set('includeBlue', includeBlue);
    }

    return this.get(endpoint, params).pipe(map(
        (data) => {
          console.log(data);
          return data;
        },
        (error) => {
          return error;
        },
    ));
  }

  sendVideoSocketAction(actionData) {
    this.videoSocket.send(JSON.stringify(actionData));
  }

  createVideoSocket() {
    this.videoSocket = createThunderWebSocket(this.videoSocket, `video`, this.broadcastChannelService);
    return this.videoSocket;
  }

  closeVideoSocket(attemptReconnect) {
    if (!attemptReconnect && this.videoSocket) {
      this.videoSocket.onclose = (event) => {
        console.log('Socket should be closed for good');
      };
    }
    if (this.videoSocket) {
      this.videoSocket.close();
    }
  }

  generateChainID() {
    return (Math.random() + 1).toString(36).substring(7);
  }

  private convertEntityToNBAIDs(entityIDs) {
    return this.entityNBALookup.filter((mapping) => entityIDs.includes(mapping[0])).map((mapping) => mapping[1]);
  }

  private convertEntityToSynergyIDs(entityIDs) {
    return this.entitySynergyLookup.filter((mapping) => entityIDs.includes(mapping[0])).map((mapping) => mapping[1]);
  }

  private convertSportradarToSynergyIDs(sportradarIDs) {
    const playerEntityIDs = this.entitySportradarLookup.filter((mapping) => sportradarIDs.includes(mapping[1])).map((mapping) => mapping[0]);
    return this.convertEntityToSynergyIDs(playerEntityIDs);
  }

  addNCAASetupFilters(filtersData, videoParams) {
    if (videoParams.setup === 'Catch & Shoot') {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'shot_setup_advanced',
        'not': false,
        'args': this.CATCH_AND_SHOOT_PARAMETERS,
      });
    } else {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'shot_setup_advanced',
        'not': false,
        'args': ['OTD'],
      });
    }
  }

  addPlayTypeFilters(videoParams, filtersData) {
    const actionName = synergyActionNameMapper(videoParams.playType);
    // We want to filter pick and rolls to the relevant subtypes
    if (videoParams.playType === 'PandRBallHandler' || videoParams.playType === 'PandRRollMan') {
      videoParams.variant = videoParams.playType;
    }
    filtersData['filters'].push({
      'table': VideoTables.ACTION,
      'name': 'name',
      'group': videoActionToGroupName(actionName),
      'not': false,
      'args': [actionName],
    });
    filtersData['filters'].push({
      'table': VideoTables.ACTION,
      'name': 'role',
      'group': videoActionToGroupName(actionName),
      'not': false,
      'args': ['Finisher'],
    });
    if (videoParams.variant) {
      filtersData['filters'].push({
        'table': VideoTables.ACTION,
        'name': 'descriptors',
        'group': videoActionToGroupName(actionName),
        'not': false,
        'args': [videoParams.variant],
      });
    }
  }

  getLeagueFromGame(gameID: number) {
    switch (gameID.toString().length) {
      case 8:
        return [1];
      case 10:
        return [2];
      default:
        return [101];
    }
  }

  configureFiltersData(videoParams) {
    const metricTypesToSubject = {
      [VideoMetrics.CHANCES]: 'chances',
      [VideoMetrics.POINTS]: 'points',
      [VideoMetrics.SHOTS]: 'shots',
      [VideoMetrics.FIELD_GOAL_MAKES]: 'shots',
      [VideoMetrics.FIELD_GOAL_ATTEMPTS]: 'shots',
      [VideoMetrics.TWO_POINT_MAKES]: 'shots',
      [VideoMetrics.TWO_POINT_ATTEMPTS]: 'shots',
      [VideoMetrics.THREE_POINT_MAKES]: 'shots',
      [VideoMetrics.THREE_POINT_ATTEMPTS]: 'shots',
      [VideoMetrics.FREE_THROW_MAKES]: 'free_throws',
      [VideoMetrics.FREE_THROW_ATTEMPTS]: 'free_throws',
      [VideoMetrics.ASSISTS]: 'assists',
      [VideoMetrics.TOTAL_REBOUNDS]: 'rebounds',
      [VideoMetrics.OFFENSIVE_REBOUNDS]: 'rebounds',
      [VideoMetrics.DEFENSIVE_REBOUNDS]: 'rebounds',
      [VideoMetrics.STEALS]: 'steals',
      [VideoMetrics.BLOCKS]: 'blocks',
      [VideoMetrics.TURNOVERS]: 'turnovers',
      [VideoMetrics.FOULS]: 'fouls',
      [VideoMetrics.FOULS_DRAWN]: 'fouls',
      [VideoMetrics.FT_DRAWN]: 'free_throws',
      [VideoMetrics.CHARTING]: 'charting',
      [VideoMetrics.TOUCHES]: 'touches',
      [VideoMetrics.USAGE]: 'usage',
      [VideoMetrics.PLAYCALLS]: 'chances',
      [VideoMetrics.PLAYCALLS_SHOTS]: 'shots',
      [VideoMetrics.PLAYCALLS_FT_DRAWN]: 'chances',
      [VideoMetrics.PLAYCALLS_TURNOVERS]: 'turnovers',
      [VideoMetrics.PLAYCALLS_ORBS]: 'rebounds',
      [VideoMetrics.PLAYCALLS_ASSISTS]: 'assists',
      [VideoMetrics.PLAYCALLS_TWO_POINT_MAKES]: 'shots',
      [VideoMetrics.PLAYCALLS_TWO_POINT_ATTEMPTS]: 'shots',
      [VideoMetrics.PLAYCALLS_THREE_POINT_MAKES]: 'shots',
      [VideoMetrics.PLAYCALLS_THREE_POINT_ATTEMPTS]: 'shots',
      [VideoMetrics.PLAYCALLS_FIELD_GOAL_MAKES]: 'shots',
      [VideoMetrics.PLAYCALLS_FIELD_GOAL_ATTEMPTS]: 'shots',
      [VideoMetrics.PLAYCALLS_FREE_THROW_MAKES]: 'free_throws',
      [VideoMetrics.PLAYCALLS_FREE_THROW_ATTEMPTS]: 'free_throws',
    };
    if (!videoParams.leagueIDs?.length && videoParams.gameID) {
      videoParams.leagueIDs = this.getLeagueFromGame(videoParams.gameID);
    }
    videoParams.leagueIDs = videoParams.leagueIDs.map((leagueID) => bildIDToLeagueID(leagueID));
    const isNCAA = videoParams.leagueIDs[0] == this.NCAA_ID;
    const isINTL = videoParams.leagueIDs[0] == this.INTL_ID;
    const playerIdentifierColumnPrefix = isNCAA || isINTL ? 'synergy_' : 'nba_';
    const teamIdentifierColumnPrefix = isNCAA || isINTL ? 'synergy_' : 'nba_';
    const gameIdentifierColumnPrefix = isNCAA || isINTL ? 'sportradar_' : 'nba_';
    const subject = metricTypesToSubject[videoParams.metricType];
    const filtersData = {
      'subject': subject,
      'filters': [],
    };
    let playerIDs = null;
    if (videoParams.playerID && !isINTL) {
      if (isNCAA) {
        playerIDs = this.convertSportradarToSynergyIDs([videoParams.playerID]);
      } else if (isINTL) {
        playerIDs = this.convertSportradarToSynergyIDs([videoParams.playerID]);
      } else {
        playerIDs = [videoParams.playerID];
      }
    } else if (videoParams.primaryEntityID) {
      if (isNCAA) {
        playerIDs = this.convertEntityToSynergyIDs([videoParams.primaryEntityID]);
      } else if (isINTL) {
        playerIDs = this.convertEntityToSynergyIDs([videoParams.primaryEntityID]);
      } else {
        playerIDs = this.convertEntityToNBAIDs([videoParams.primaryEntityID]);
      }
    }
    if (videoParams.metricType == VideoMetrics.PLAYCALLS_SHOTS) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'shot_attempt',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.PLAYCALLS_FREE_THROW_MAKES) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'ft_made',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.PLAYCALLS_FT_DRAWN || videoParams.metricType == VideoMetrics.PLAYCALLS_FREE_THROW_ATTEMPTS) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'ft_attempted',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.PLAYCALLS_TURNOVERS) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'turnover',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.PLAYCALLS_ASSISTS) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'assist',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.FIELD_GOAL_MAKES || videoParams.metricType == VideoMetrics.PLAYCALLS_FIELD_GOAL_MAKES) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'fg_made',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.FIELD_GOAL_ATTEMPTS || videoParams.metricType == VideoMetrics.PLAYCALLS_FIELD_GOAL_ATTEMPTS) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'fg_attempted',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.TWO_POINT_MAKES || videoParams.metricType == VideoMetrics.PLAYCALLS_TWO_POINT_MAKES) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'fg_2_made',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.TWO_POINT_ATTEMPTS || videoParams.metricType == VideoMetrics.PLAYCALLS_TWO_POINT_ATTEMPTS) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'fg_2_attempted',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.THREE_POINT_MAKES || videoParams.metricType == VideoMetrics.PLAYCALLS_THREE_POINT_MAKES) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'fg_3_made',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.THREE_POINT_ATTEMPTS || videoParams.metricType == VideoMetrics.PLAYCALLS_THREE_POINT_ATTEMPTS) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'fg_3_attempted',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.OFFENSIVE_REBOUNDS || videoParams.metricType == VideoMetrics.PLAYCALLS_ORBS) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'reb_offensive',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.metricType == VideoMetrics.DEFENSIVE_REBOUNDS) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'reb_defensive',
        'not': false,
        'args': [1],
      });
    }
    if (videoParams.leagueIDs && !isINTL) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'league_id',
        'not': false,
        'args': videoParams.leagueIDs,
      });
    }
    if (videoParams.seasons) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'season',
        'not': false,
        'args': videoParams.seasons,
      });
    }
    if (videoParams.gameTypes && !isNCAA && !isINTL) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'game_type',
        'not': false,
        'args': videoParams.gameTypes,
      });
    }
    if (videoParams.gameID) {
      if (isNCAA && this.sportradarSynergyGameMap[videoParams.gameID]) {
        filtersData['filters'].push({
          'table': 'chancedetails',
          'name': 'synergy_game_id',
          'not': false,
          'args': [this.sportradarSynergyGameMap[videoParams.gameID]],
        });
      } else {
        filtersData['filters'].push({
          'table': 'chancedetails',
          'name': gameIdentifierColumnPrefix + 'game_id',
          'not': false,
          'args': [videoParams.gameID],
        });
      }
    }
    if (playerIDs) {
      const playerFilter = {
        'table': 'abstract',
        'name': playerIdentifierColumnPrefix + 'subject_player_id',
        'not': false,
        'args': playerIDs,
      };
      if (videoParams.playType) {
        const actionName = synergyActionNameMapper(videoParams.playType);
        playerFilter['group'] = videoActionToGroupName(actionName);
      }
      filtersData['filters'].push(playerFilter);
    }
    if (videoParams.offTeamID) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': teamIdentifierColumnPrefix + 'offensive_team_id',
        'not': false,
        'args': isNCAA ? this.convertSportradarToSynergyIDs([videoParams.offTeamID]) : [videoParams.offTeamID],
      });
    }
    if (videoParams.defTeamID) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': teamIdentifierColumnPrefix + 'defensive_team_id',
        'not': false,
        'args': isNCAA ? this.convertSportradarToSynergyIDs([videoParams.defTeamID]) : [videoParams.defTeamID],
      });
    }
    if (videoParams.homeAway) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'home_away',
        'not': false,
        'args': [videoParams.homeAway],
      });
    }
    if (videoParams.distribution && videoParams.distribution != 'ALL') {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'shot_distribution',
        'not': false,
        'args': [videoParams.distribution],
      });
    }
    if (videoParams.setup && !isNCAA) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'shot_setup',
        'not': false,
        'args': [videoParams.setup],
      });
    }
    if (videoParams.setup && isNCAA) {
      this.addNCAASetupFilters(filtersData, videoParams);
    }
    if (videoParams.contestedness) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'shot_contestedness',
        'not': false,
        'args': [videoParams.contestedness],
      });
    }
    if (videoParams.zone && videoParams.zone != 'ALL' && videoParams.subzone === 'ALL') {
      let zones = [videoParams.zone];
      if (videoParams.zone === '3S') {
        zones = ['C3', 'NC3'];
      } else if (videoParams.zone === 'NDP2') {
        zones = ['BLK', 'HP', 'NP2'];
      }
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'shot_zone_basic',
        'not': false,
        'args': zones,
      });
    }
    if (videoParams.subzone && videoParams.subzone != 'ALL') {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'shot_zone_basic',
        'not': false,
        'args': [videoParams.subzone],
      });
    }
    if (videoParams.chartingCategory) {
      filtersData['filters'].push({
        'table': 'chartingdetails',
        'name': 'charting_category_name',
        'not': false,
        'args': [videoParams.chartingCategory],
      });
    }
    if (videoParams.chartingResult) {
      filtersData['filters'].push({
        'table': 'chartingdetails',
        'name': 'charting_result',
        'not': false,
        'args': [videoParams.chartingResult],
      });
    }
    if (videoParams.includeGarbageTime != null && videoParams.includeGarbageTime != undefined) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'garbage_time',
        'not': false,
        'args': [videoParams.includeGarbageTime],
      });
    }
    if (videoParams.startingPoints) {
      filtersData['filters'].push({
        'table': 'chancedetails',
        'name': 'nba_starting_point',
        'not': false,
        'args': videoParams.startingPoints,
      });
    }
    if (videoParams.includeIndirect == false) {
      filtersData['filters'].push({
        'table': 'playcalldetails',
        'name': 'direct',
        'group': 'playcalls',
        'not': false,
        'args': [true],
      });
    }
    if (videoParams.excludePlaycalls) {
      filtersData['filters'].push({
        'table': 'playcalldetails',
        'name': 'playcall_name',
        'group': 'playcalls',
        'not': true,
        'args': videoParams.excludePlaycalls,
      });
    }
    if (videoParams.playcallName) {
      filtersData['filters'].push({
        'table': 'abstract',
        'name': 'playcall_name',
        'group': 'playcalls',
        'not': false,
        'args': [videoParams.playcallName],
      });
    }
    if (videoParams.defenseType && videoParams.defenseType != 'ALL') {
      filtersData['filters'].push({
        'table': 'abstract',
        'name': 'defense_type',
        'group': 'playcalls',
        'not': false,
        'args': [videoParams.defenseType],
      });
    }
    if (videoParams.combineATOMatches) {
      filtersData['filters'].push({
        'table': 'abstract',
        'name': 'combine_ato_matches',
        'group': 'playcalls',
        'not': false,
        'args': [true],
      });
    }
    if (videoParams.includeATOPlaycalls !== null && videoParams.includeATOPlaycalls !== undefined) {
      filtersData['filters'].push({
        'table': 'abstract',
        'name': 'include_ato_playcalls',
        'group': 'playcalls',
        'not': false,
        'args': [videoParams.includeATOPlaycalls],
      });
    }
    if (videoParams.includeNoTypePlaycalls !== null && videoParams.includeNoTypePlaycalls !== undefined) {
      filtersData['filters'].push({
        'table': 'abstract',
        'name': 'include_no_type_playcalls',
        'group': 'playcalls',
        'not': false,
        'args': [videoParams.includeNoTypePlaycalls],
      });
    }
    if (videoParams.playType) {
      this.addPlayTypeFilters(videoParams, filtersData);
    }
    return filtersData;
  }

  updateFolder(folder: any) {
    this.folderSource.next(folder);
  }

  updateFolderPath(folderName: string) {
    this.folderPath.next(folderName);
  }
}
