import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReplacePipe} from '@helpers/replace.pipe';

import {NumberOrPlaceholderPipe} from './number-or-placeholder.pipe';
import {PhonePipe} from 'apps/_helpers/phone-format.pipe';
import {SafePipe} from '@helpers/safe.pipe';
import {UnitFormatPipe} from '@helpers/unit-format.pipe';
import { PlayerPositionPipe } from './player-position.pipe';
import { RoundPipe } from '@helpers/round.pipe';
import {CounterPipe} from '@helpers/counter.pipe';
import { FgPipe } from './fg.pipe';
import { SalaryValuePipe } from './salary.pipe';
import { SeasonStartEndPipe } from './season.pipe';
import {TimePipe} from "@helpers/time.pipe";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ReplacePipe,
    UnitFormatPipe,
    SafePipe,
    PhonePipe,
    NumberOrPlaceholderPipe,
    PlayerPositionPipe,
    RoundPipe,
    CounterPipe,
    FgPipe,
    SalaryValuePipe,
    SeasonStartEndPipe,
    TimePipe,
  ],
  exports: [
    ReplacePipe,
    UnitFormatPipe,
    SafePipe,
    PhonePipe,
    NumberOrPlaceholderPipe,
    PlayerPositionPipe,
    RoundPipe,
    CounterPipe,
    FgPipe,
    SalaryValuePipe,
    SeasonStartEndPipe,
    TimePipe,
  ],
  providers: [
    UnitFormatPipe,
    TimePipe,
    DecimalPipe,
    SalaryValuePipe,
  ]
})
export class HelpersModule {}
