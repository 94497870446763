import * as _ from 'lodash';


import {Injectable} from '@angular/core';
import {NgxPermissionsObject} from 'ngx-permissions';
import {Team} from '@models/teams';
import {Entity} from '@models/entities';
import {Person} from '@models/people';
import {ENTITY_SUBTYPE_IDS} from '@models/constants/entity-subtypes';
import {League} from '@models/leagues';
import {LEAGUE_IDS} from '@models/constants/leagues';
import {
  PersonAffiliationsNavOption, PersonCommunityBoardNavOption,
  PersonComparisonNavOption,
  PersonDevelopmentNavOption,
  PersonEvaluationNavOption,
  PersonFocusGroupsNavOption,
  PersonHighlightsNavOption,
  PersonMetricsNavOption, PersonOwnershipProfileNavOption, PersonPersonalNavOption,
  PersonPhysicalNavOption,
  PersonPostFeedNavOption, PersonPsychNavOption,
} from '@models/constants/nav-options/person-nav-options';
import {
  TeamAffiliationsNavOption,
  TeamCommunityBoardNavOption,
  TeamDevelopmentNavOption, TeamEvaluationNavOption, TeamEventsNavOption,
  TeamHighlightsNavOption, TeamInformationNavOption, TeamMetricsNavOption, TeamPhysicalNavOption,
  TeamPostFeedNavOption,
  TeamRosterNavOption, TeamScoreboardNavOption,
} from '@models/constants/nav-options/team-nav-options';
import {NavOption} from '@models/nav-option';
import {
  BildEventsNavOption,
  BildFocusGroupsNavOption,
  BildHighlightsNavOption, BildMetricsNavOption,
  BildPostFeedNavOption,
  BildScoreboardNavOption, BildTalentPipelineNavOption, BildPlayerExplorerNavOption,
  BildVideoNavOption, BildScheduleNavOption,
} from '@models/constants/nav-options/bild-nav-options';
import {
  LeagueEvaluationNavOption,
  LeagueHighlightsNavOption,
  LeaguePostFeedNavOption, LeagueRostersNavOption,
} from '@models/constants/nav-options/league-nav-options';
import {
  SiteManagementCacheNavOption,
  SiteManagementDataQualityNavOption,
  SiteManagementEntitiesNavOption,
  SiteManagementMomentsNavOption,
  SiteManagementTagManagementNavOption, SiteManagementUserGroupsNavOption, SiteManagementUsersNavOption,
} from '@models/constants/nav-options/site-managament-nav-options';
import {TagHighlightingNavOption, TagPostFeedNavOption} from '@models/constants/nav-options/tag-nav-options';
import {
  DataEntryOfficiatingNavOption,
  DataEntrySessionsNavOption, DataEntryShotChartingNavOption,
  DataEntrySportscodeNavOption,
} from '@models/constants/nav-options/data-entry-nav-options';
import {
  TeamSalaryNavOption,
  LeagueSalaryNavOption,
} from '@models/constants/nav-options/salary-nav-options';
import {
  DiagnosticsNavOption,
  GenerateNavOption,
  LiveNavOption,
  UploadNavOption,
  VideoNavOption,
} from '@models/constants/nav-options/video-nav-options';

import {scheduleIds} from 'apps/bild/_shared/directives/feature-toggle.directive';
import { User } from '@models/users';

const THUNDER_ID = 2;
const BLUE_ID = 11569;
const NBA_LEAGUE_ID = 41;
const GLG_LEAGUE_ID = 44;
const NCAA_LEAGUE_ID = 42;
const EVENT_ID = 48;

// Used for mobile nav
export enum EntityLeagueNavTab {
  POST_FEED = 'Post Feed',
  HIGHLIGHTS = 'Highlights',
  EVALUATION = 'Evaluation',
  ROSTERS = 'Rosters'
}

// Used for mobile nav
export enum EntityTeamNavTab {
  POST_FEED = 'Post Feed',
  HIGHLIGHTS = 'Highlights',
  ROSTER = 'Roster',
  PLAYER_DEVELOPMENT = 'Player Development',
  EVALUATION = 'Evaluation',
  METRICS = 'Metrics',
  PHYSICAL = 'Physical',
  SCOREBOARD = 'Scoreboard',
  COMMUNITY_BOARD = 'Community Board',
  TEAM_INFORMATION = 'Team Information',
  EVENTS = 'Events',
  AFFILIATIONS = 'Affiliations'
}

// Used for mobile nav
export enum EntityPersonNavTab {
  POST_FEED = 'Post Feed',
  HIGHLIGHTS = 'Highlights',
  EVALUATION = 'Evaluation',
  COMPARISON = 'Comparison',
  PLAYER_DEVELOPMENT = 'Player Development',
  AFFILIATIONS = 'Affiliations',
  FOCUS_GROUPS = 'Focus Groups',
  METRICS = 'Metrics',
  PHYSICAL = 'Physical',
  PSYCH = 'Psych',
  COMMUNITY_BOARD = 'Community Board',
  PERSONAL = 'Personal',
  OWNERSHIP_PROFILE = 'Ownership Profile',
  EVENTS = 'Events',
}

export interface EntityNavTab {
  name: EntityLeagueNavTab | EntityTeamNavTab | EntityPersonNavTab;
  shouldHide: boolean;
}

@Injectable({
  providedIn: 'root',
  })
export class EntityNavTabService {
  private _expanded: boolean = false;

  constructor() {}

  get expanded() {
    return this._expanded;
  }
  set expanded(value: boolean) {
    this._expanded = value;
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  resetExpanded() {
    this.expanded = false;
  }

  getPersonNavTabs(person: Person | Entity, perms: NgxPermissionsObject, isMobile: boolean): NavOption[] {
    const personSubtypeIDs = person && (person as Person).additionalPersonTypes ? (person as Person).additionalPersonTypes.map((t) => t.id) : [];
    const personPrimarySubtypeID = person && (person as Person).primaryPersonType ? (person as Person).primaryPersonType.id : person && person.subtype ? person.subtype.id : null;
    personSubtypeIDs.push(personPrimarySubtypeID);

    const isTalent = person?.isInTalentPipeline && !person?.hasContent;

    const tabs: NavOption[] = [];

    if (isTalent) {
      if (perms.viewAllPersonalInformation) {
        tabs.push(new PersonPersonalNavOption(person));
      }

      if (perms.editAllAffiliations && personPrimarySubtypeID !== ENTITY_SUBTYPE_IDS.MEDIA) {
        tabs.push(new PersonAffiliationsNavOption(person));
      }
    } else {
      tabs.push(new PersonPostFeedNavOption(person));
      if (isMobile) {
        tabs.push(new PersonHighlightsNavOption(person));
      }

      if (
        (perms.viewAllMetrics || perms.viewOwnerMetrics) &&
        (personSubtypeIDs.includes(ENTITY_SUBTYPE_IDS.PLAYER))
      ) {
        tabs.push(new PersonMetricsNavOption(person));
      }

      if (
        (perms.viewAllDevelopment || perms.viewOwnerDevelopment) &&
        (personSubtypeIDs.includes(ENTITY_SUBTYPE_IDS.PLAYER))
      ) {
        tabs.push(new PersonDevelopmentNavOption(person));
      }

      if ((perms.viewAllLOCs || perms.viewOwnerLOCs) && !(_.isEmpty(_.intersection(personSubtypeIDs, [ENTITY_SUBTYPE_IDS.STAFF, ENTITY_SUBTYPE_IDS.PLAYER])))) {
        tabs.push(new PersonEvaluationNavOption(person));
      }

      if (
        (perms.viewAllMetrics || perms.viewOwnerMetrics) &&
        (personSubtypeIDs.includes(ENTITY_SUBTYPE_IDS.PLAYER))
      ) {
        tabs.push(new PersonComparisonNavOption(person));
      }

      if (perms.viewAllPersonalInformation && personSubtypeIDs.includes(ENTITY_SUBTYPE_IDS.PLAYER)) {
        tabs.push(new PersonPhysicalNavOption(person));
      }

      if (perms.editAllAffiliations && personPrimarySubtypeID !== ENTITY_SUBTYPE_IDS.MEDIA) {
        tabs.push(new PersonAffiliationsNavOption(person));
      }

      if (perms.viewAllPersonalInformation) {
        tabs.push(new PersonPersonalNavOption(person));
      }

      if (
        (perms.viewAllFocusGroups || perms.viewOwnerFocusGroups) &&
        (personSubtypeIDs.includes(ENTITY_SUBTYPE_IDS.PLAYER))
      ) {
        tabs.push(new PersonFocusGroupsNavOption(person));
      }

      if ((perms.viewAllWellness || perms.viewAllPASS || perms.viewAllIntelligence || perms.viewAllNEO || perms.viewAllSOAP) && !(_.isEmpty(_.intersection(personSubtypeIDs, [ENTITY_SUBTYPE_IDS.STAFF, ENTITY_SUBTYPE_IDS.PLAYER])))) {
        tabs.push(new PersonPsychNavOption(person));
      }

      if (
        (perms.viewAllCommunityEvents || perms.viewOwnerCommunityEvents) &&
        (personSubtypeIDs.includes(ENTITY_SUBTYPE_IDS.PLAYER))
      ) {
        tabs.push(new PersonCommunityBoardNavOption(person));
      }

      if (perms.viewAllPersonalInformation && personSubtypeIDs.includes(ENTITY_SUBTYPE_IDS.OWNER)) {
        tabs.push(new PersonOwnershipProfileNavOption(person));
      }
    }

    return tabs;
  }

  getTeamNavTabs(team: Team | Entity, perms: NgxPermissionsObject, isMobile: boolean): NavOption[] {
    const primaryAffiliationID = (team as Team).primaryAffiliation ? (team as Team).primaryAffiliation.id : team.league ? team.league.id : null;

    const tabs: NavOption[] = [
      new TeamPostFeedNavOption(team),
    ];
    if (isMobile) {
      tabs.push(new TeamHighlightsNavOption(team));
    }

    if (
      (perms.viewAllTSA || perms.viewAllReservePrices || perms.viewAllLOCs) &&
      ([NBA_LEAGUE_ID, GLG_LEAGUE_ID, NCAA_LEAGUE_ID].includes(primaryAffiliationID))
    ) {
      tabs.push(new TeamEvaluationNavOption(team));
    }

    if (
      (perms.viewAllDevelopment || perms.viewOwnerDevelopment) &&
      (team.id === BLUE_ID || team.id === THUNDER_ID)
    ) {
      tabs.push(new TeamDevelopmentNavOption(team));
    }

    if (
      (perms.viewAllMetrics || perms.viewOwnerMetrics) &&
      ([NBA_LEAGUE_ID, GLG_LEAGUE_ID, NCAA_LEAGUE_ID].includes(primaryAffiliationID))
    ) {
      tabs.push(new TeamMetricsNavOption(team));
    }

    if (perms.viewAllEvents) {
      tabs.push(new TeamEventsNavOption(team));
    }

    if (team.id === BLUE_ID || team.id === THUNDER_ID) {
      tabs.push(new TeamPhysicalNavOption(team));
    }

    if (perms.viewAllRoster && !(primaryAffiliationID === EVENT_ID)) {
      tabs.push(new TeamRosterNavOption(team));
    }

    tabs.push(new TeamScoreboardNavOption(team));

    if (
      (perms.viewAllCommunityEvents || perms.viewOwnerCommunityEvents) &&
      (team.id === BLUE_ID || team.id === THUNDER_ID)
    ) {
      tabs.push(new TeamCommunityBoardNavOption(team));
    }

    if (perms.editAllAffiliations) {
      tabs.push(new TeamAffiliationsNavOption(team));
    }

    if (perms.viewAllTeamInformation) {
      tabs.push(new TeamInformationNavOption(team));
    }

    return tabs;
  }

  getLeagueNavTabs(league: League | Entity, perms: NgxPermissionsObject, isMobile: boolean): NavOption[] {
    const tabs: NavOption[] = [
      new LeaguePostFeedNavOption(league),
    ];
    if (isMobile) {
      tabs.push(new LeagueHighlightsNavOption(league));
    }

    if (
      (perms.viewAllLOCs || perms.viewAllTSA || perms.viewAllReservePrices)
    ) {
      tabs.push(new LeagueEvaluationNavOption(league));
    }

    if (league.id == LEAGUE_IDS.NBA) {
      tabs.push(new LeagueRostersNavOption(league));
    }

    return tabs;
  }

  getBildNavTabs(perms: NgxPermissionsObject, isMobile: boolean, currentUserID: number): NavOption[] {
    const tabs: NavOption[] = [
      new BildPostFeedNavOption(),
    ];

    if (isMobile) {
      tabs.push(new BildHighlightsNavOption());
    }

    if (
      (perms.viewAllMetrics)
    ) {
      tabs.push(new BildScoreboardNavOption());
    }

    if (
      (perms.viewAllFocusGroups || perms.viewOwnerFocusGroups)
    ) {
      tabs.push(new BildFocusGroupsNavOption());
    }

    if (
      (perms.viewAllMetrics)
    ) {
      tabs.push(new BildMetricsNavOption());
    }

    if (
      (perms.viewAllTalentPipeline)
    ) {
      tabs.push(new BildTalentPipelineNavOption());
    }

    if (
      (perms.viewAllEvents || perms.viewOwnerEvents)
    ) {
      tabs.push(new BildEventsNavOption());
    }

    if (perms.viewAllMetrics || perms.viewOwnerMetrics) {
      tabs.push(new BildPlayerExplorerNavOption());
    }

    // TODO: Add permissions
    if (scheduleIds.includes(currentUserID)) {
      tabs.push(new BildScheduleNavOption());
    }


    return tabs;
  }

  getSiteManagementNavTabs(perms: NgxPermissionsObject): NavOption[] {
    const tabs: NavOption[] = [];

    if (
      (perms.Admin || perms.viewAllCache)
    ) {
      tabs.push(new SiteManagementCacheNavOption());
    }

    if (
      (perms.Admin || perms.viewAllDataQuality)
    ) {
      tabs.push(new SiteManagementDataQualityNavOption());
    }

    if (
      (perms.Admin || perms.editAllEntityCreation)
    ) {
      tabs.push(new SiteManagementEntitiesNavOption());
    }

    if (
      (perms.Admin || perms.editAllAnnouncements)
    ) {
      tabs.push(new SiteManagementMomentsNavOption());
    }

    if (
      (perms.Admin || perms.editAllTagManagement)
    ) {
      tabs.push(new SiteManagementTagManagementNavOption());
    }

    if (
      (perms.Admin)
    ) {
      tabs.push(new SiteManagementUsersNavOption());
      tabs.push(new SiteManagementUserGroupsNavOption());
    }

    return tabs;
  }

  getTagNavTabs(tagID: number, perms: NgxPermissionsObject): NavOption[] {
    const tabs: NavOption[] = [];

    if (
      (perms.viewAllPosts || perms.viewOwnerPosts)
    ) {
      tabs.push(new TagPostFeedNavOption(tagID));
      tabs.push(new TagHighlightingNavOption(tagID));
    }

    return tabs;
  }

  getDataEntryNavTabs(perms: NgxPermissionsObject): NavOption[] {
    const tabs: NavOption[] = [];

    if (
      (perms.Admin || perms.viewAllDataQuality)
    ) {
      tabs.push(new DataEntrySportscodeNavOption());
    }

    if (
      (perms.Admin || perms.viewAllShotCharting)
    ) {
      tabs.push(new DataEntrySessionsNavOption());
      tabs.push(new DataEntryShotChartingNavOption());
    }

    if (
      (perms.Admin || perms.viewAllMetrics)
    ) {
      tabs.push(new DataEntryOfficiatingNavOption());
    }

    return tabs;
  }

  getSalaryNavTabs(perms: NgxPermissionsObject): NavOption[] {
    const tabs: NavOption[] = [];
    tabs.push(new TeamSalaryNavOption());
    tabs.push(new LeagueSalaryNavOption());

    return tabs;
  }

  getVideoNavTabs(perms: NgxPermissionsObject): NavOption[] {
    return [
      new VideoNavOption(),
      new GenerateNavOption(),
      new LiveNavOption(),
      new UploadNavOption(),
      new DiagnosticsNavOption(),
    ];
  }
}
