import {NavOption} from '@models/nav-option';
import {LEAGUE_IDS} from '@models/constants/leagues';

export class DataEntrySportscodeNavOption extends NavOption {
  name= 'Sportscode';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'data-entry', 'sportscode'];
  link = ['', 'bild', 'data-entry', 'sportscode'];
}

export class DataEntrySessionsNavOption extends NavOption {
  name= 'Sessions';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'data-entry', 'sessions'];
  link = ['', 'bild', 'data-entry', 'sessions'];
}

export class DataEntryShotChartingNavOption extends NavOption {
  name= 'Shot Charting';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'data-entry', 'shot-charting'];
  link = ['', 'bild', 'data-entry', 'shot-charting'];
}

export class DataEntryOfficiatingNavOption extends NavOption {
  name= 'Officiating';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'data-entry', 'officiating'];
  link = ['', 'bild', 'data-entry', 'officiating'];
}
