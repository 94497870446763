import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'salaryValue'
})
export class SalaryValuePipe implements PipeTransform {

  constructor() {}

  transform(val: number, isMobile?: boolean): string {
    if (val == null) {
      return '';
    }

    let abs = Math.abs(val);

    return (val < 0 ? '(' : '') +
      (isMobile ? (abs >= 1000000 ? ((Math.round(abs / 100000) / 10).toFixed(1) + ' M') : (Math.round(abs / 1000) + (abs > 0 ? ' K' : ''))) : Math.round(abs).toLocaleString()) +
      (val < 0 ? ')' : '');
  }
}
