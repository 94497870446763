
import {OwnershipProfile} from '@models/ownership-profiles';
import {of as observableOf, BehaviorSubject, Observable} from 'rxjs';
import * as _ from 'lodash';
import * as Moment from 'moment-timezone';
import {map, share} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {BaseService} from './base.service';
import {EntitySubtypesService} from './id-names/entity-subtypes.service';
import {Affiliation, AffiliationRelationship} from '@models/affiliations';
import {Entity} from '@models/entities';
import {EntityType, EntityTypeIDMap} from '@models/constants/entity-types';
import {Level} from '@models/constants/levels';
import {Person, DraftPerson} from '@models/people';
import {Team, DraftTeam} from '@models/teams';
import {RosterEntity, Roster} from '@models/roster-entity';
import {plainToClass} from 'class-transformer';
import {Anthro} from '@models/physical/anthro';
import {PhysicalActivity} from '@models/physical-activities';
import {League} from '@models/leagues';
import { FlatPhysicalMetric } from '@models/physical/flat-physical-metrics';
import {Position} from '@models/ranked-people';
import { InjuryOccurrence } from '@models/physical/injuryOccurrences';
import { OwnershipHeadline } from '@models/ownership-headlines';
import { PlayerDevelopmentObjective } from '@models/player-development-objectives';
import {BroadcastChannelService} from '@services/broadcast-channel.service';
import {createThunderWebSocket, ThunderWebSocket} from "@models/thunder-web-socket";

@Injectable()
export class EntitiesService extends BaseService {
  rosterDataSubject: BehaviorSubject<Entity[]> = new BehaviorSubject<Entity[]>([]);
  rosterData: Observable<Entity[]>;

  rosterMeasuresDataSubject: BehaviorSubject<RosterEntity[]> = new BehaviorSubject<RosterEntity[]>([]);
  rosterMeasuresData: Observable<RosterEntity[]>;

  affiliationsWebSocket: ThunderWebSocket;
  leagueRostersWebSocket: ThunderWebSocket;

  protected _person$: BehaviorSubject<Person> = new BehaviorSubject<Person>(null);

  constructor(protected http: HttpClient, protected broadcastChannelService: BroadcastChannelService,
              protected authService: AuthService,
              protected entitySubtypesService: EntitySubtypesService) {
    super(http, broadcastChannelService);

    this.rosterData = this.rosterDataSubject.asObservable();
    this.rosterMeasuresData = this.rosterMeasuresDataSubject.asObservable();
  }

  getEntities(entityType: string): Observable<Entity[]> {
    const endpoint = `${this.baseUrl}/entities?entityType=${entityType}`;
    return this.get(endpoint).pipe(map(
      data => {
        data = plainToClass(Entity, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getEntity(id: number): Observable<Entity> {
    const endpoint = `${this.baseUrl}/entities/${id}`;
    return this.get(endpoint).pipe(map(
      data => {
        data = plainToClass(Entity, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getLeagueDetails(id: number): Observable<League> {
    const endpoint = `${this.baseUrl}/leagues/${id}/details`;
    return this.get(endpoint).pipe(map(
      data => {
        data = plainToClass(League, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getLeagueRosters(id: number, page?: string): Observable<any[]> {
    const endpoint = `${this.baseUrl}/leagues/${id}/rosters`;
    let params: HttpParams = new HttpParams();
    if (page) {
      params = params.set('page', page);
    }
    return this.get(endpoint, params).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  createLeagueRostersSocket(id: number) {
    this.leagueRostersWebSocket = createThunderWebSocket(this.leagueRostersWebSocket, `v1/leagues/${id}/rosters`, this.broadcastChannelService);
    return this.leagueRostersWebSocket;
  }

  closeLeagueRostersSocket(attemptReconnect) {
    if (!attemptReconnect && this.leagueRostersWebSocket) {
      this.leagueRostersWebSocket.onclose = (event) => {
        console.log('Socket should be closed for good');
      };
    }

    if (this.leagueRostersWebSocket) {
      this.leagueRostersWebSocket.close();
    }
  }

  getPublicDistribution(postType: string, postCategories: any[], postLevel: Level): Observable<Entity[]> {
    const endpoint = `${this.baseUrl}/posts/distribution/public`;
    const ttl: number = 10;

    let params: HttpParams = new HttpParams();
    params = params.set('postType', postType);
    if (postLevel) {
      params = params.set('postLevel', postLevel.name);
    }
    params = params.set('postCategories', postCategories.map((category) => {
      return category.name;
    }).join(','));

    return this.get(endpoint, params).pipe(map(
      data => {
        data = plainToClass(Entity, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getPersonDetails(id: number, forceRecache: boolean = false): Observable<Person> {
    const endpoint = `${this.baseUrl}/people/${id}/details`;
    let params: HttpParams = new HttpParams();
    params = params.set('forceRecache', forceRecache);

    return this.get(endpoint, params).pipe(map(
      data => {
        data = plainToClass(Person, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  savePerson(person: any): Observable<Person> {
    const endpoint = `${this.baseUrl}/people`;
    const personCopy = _.cloneDeep(person);
    delete personCopy.primaryAffiliation;
    delete personCopy.additionalAffiliations;

    return this.post(endpoint, personCopy).pipe(map(
      data => {
        data = plainToClass(Entity, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  updatePersonDetails(person: any): Observable<Person> {
    const endpoint = `${this.baseUrl}/people/${person.id}/details`;
    const personCopy = _.cloneDeep(person);
    delete personCopy.primaryAffiliation;
    delete personCopy.additionalAffiliations;

    return this.put(endpoint, personCopy).pipe(map(
      data => {
        data = plainToClass(Entity, data);
        this._person$.next(data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getEntityInjuries(id: number) {
    const endpoint = `${this.baseUrl}/entities/${id}/injuries`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getInjuryOptions() {
    const endpoint = `${this.baseUrl}/injuries/options`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getEntityInjuryOccurrences(id: number) {
    const endpoint = `${this.baseUrl}/entities/${id}/injuryOccurrences`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  saveEntityInjuryOccurrences(results: InjuryOccurrence[], id: number): Observable<object[]> {
    const endpoint = `${this.baseUrl}/entities/${id}/injuryOccurrences`;
    return this.put(endpoint, results).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getEntityInjurySummary(id: number) {
    const endpoint = `${this.baseUrl}/entities/${id}/injurySummary`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getTeamDetails(id: number): Observable<Team> {
    const endpoint = `${this.baseUrl}/teams/${id}/details`;
    return this.get(endpoint).pipe(map(
      data => {
        data = plainToClass(Team, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  saveTeam(team: any): Observable<Team> {
    const endpoint = `${this.baseUrl}/teams`;
    return this.post(endpoint, team).pipe(map(
      data => {
        data = plainToClass(Entity, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  updateTeamDetails(team: any): Observable<Team> {
    const endpoint = `${this.baseUrl}/teams/${team.id}/details`;
    return this.put(endpoint, team).pipe(map(
      data => {
        data = plainToClass(Entity, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getRoster(id: number): Observable<Entity[]> {
    const endpoint = `${this.baseUrl}/teams/${id}/roster`;

    return this.get(endpoint).pipe(map(
      data => {
        data = plainToClass(Entity, data);
        this.rosterDataSubject.next(data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getRosterMeasures(team: Team, leagueID?: number, season?: number, gameTypes?: number[], endOfSeasonRoster?: boolean,
                    minDate?: Moment.Moment, maxDate?: Moment.Moment, minGame?: number, maxGame?: number): Observable<Roster> {
    const id = team.id;
    const endpoint = `${this.baseUrl}/teams/${id}/rosterMeasures`;

    let params: HttpParams = new HttpParams();
    if (leagueID) {
      params = params.set('leagueID', String(leagueID));
    }
    if (season) {
      params = params.set('season', String(season));
    }
    if (gameTypes) {
      params = params.set('gameTypes', String(gameTypes));
    }
    if (endOfSeasonRoster != null) {
      params = params.set('endOfSeasonRoster', String(endOfSeasonRoster));
    }
    if (minDate) {
      params = params.set('minDate', minDate.format('YYYY-MM-DD'));
    }
    if (maxDate) {
      params = params.set('maxDate', maxDate.format('YYYY-MM-DD'));
    }
    if (minGame) {
      params = params.set('minGame', String(minGame));
    }
    if (maxGame) {
      params = params.set('maxGame', String(maxGame));
    }

    return this.get(endpoint, params).pipe(map(
      data => {
        data = plainToClass(Roster, {leagueID: leagueID, season: season, team: team, entities: data});
        this.rosterMeasuresDataSubject.next(data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  saveRosterLineup(roster: Roster): Observable<Roster> {
    const id = roster.team.id;
    const endpoint = `${this.baseUrl}/teams/${id}/roster`;

    const lineupData = _.pick(roster, ['leagueID', 'season', 'lineupData']);

    return this.put(endpoint, lineupData).pipe(map(
      data => {
        data = plainToClass(Roster, {leagueID: roster.leagueID, season: roster.season, team: roster.team, entities: data});
        this.rosterMeasuresDataSubject.next(data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getPlayerDevelopmentObjectives(id: number, isCurrent = true, isSimple = true): Observable<PlayerDevelopmentObjective[]> {
    let params: HttpParams = new HttpParams();
    if (isCurrent) {
      params = params.set('isCurrent', String(isCurrent));
    }
    if (isSimple) {
      params = params.set('isSimple', String(isSimple));
    }
    const endpoint: string = `${this.baseUrl}/entities/${id}/playerDevelopment/objectives`;

    return this.get(endpoint, params).pipe(map(
      data => {
        data = plainToClass(PlayerDevelopmentObjective, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getMetricsOverview(aboutIDs?: string, focusGroupIDs?: string, isFollowing?: boolean, isPlayers?: boolean, limit?: number): Observable<PhysicalActivity[]> {
    const endpoint = `${this.baseUrl}/physical/activity`;

    let params: HttpParams = new HttpParams();
    if (aboutIDs) {
      params = params.set('aboutIDs', String(aboutIDs));
    }
    if (isFollowing) {
      params = params.set('isFollowing', String(isFollowing));
    }
    if (limit) {
      params = params.set('limit', String(limit));
    }
    if (focusGroupIDs) {
      params = params.set('focusGroupIDs', String(focusGroupIDs));
    }
    if (isPlayers) {
      params = params.set('isPlayers', String(isPlayers));
    }

    return this.get(endpoint, params).pipe(map(
      data => {
        return plainToClass<PhysicalActivity, Object[]>(PhysicalActivity, data);
      },
      error => {
        return error;
      }
    ));
  }

  getGlobalPhysicalMetrics(aboutID?: string): Observable<any> {
    let endpoint;
    endpoint = `${this.baseUrl}/entities/${aboutID}/physical/results`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getSinglePhysicalMetrics(aboutID?: number, date?: any): Observable<FlatPhysicalMetric> {
    let endpoint;
    endpoint = `${this.baseUrl}/entities/${aboutID}/physical/results/${date.format('YYYY-MM-DD')}`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  savePhysicalMetrics(id: number, results: FlatPhysicalMetric): Observable<Anthro> {
    let endpoint: string;
    let method: any;
    results.isPro = results.level.id === 1302;

    endpoint = `${this.baseUrl}/entities/${id}/physical/results/${results.postDate.format('YYYY-MM-DD')}`;
    method = this.put.bind(this);

    return method(endpoint, results).pipe(map(
      data => {
        data = plainToClass(Anthro, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getDataQuality(league: number): Observable<Entity[]> {
    const endpoint = `${this.baseUrl}/people/missingData/${league}`;

    const params: HttpParams = new HttpParams();

    return this.get(endpoint, params).pipe(map(
      data => {
        data = plainToClass(Entity, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getAffiliations(id: number): Observable<Affiliation[]> {
    const endpoint = `${this.baseUrl}/entities/${id}/affiliations`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getAffiliationRelationships(id: number, typeIDs?: number[]): Observable<AffiliationRelationship[]> {
    const endpoint = `${this.baseUrl}/entities/${id}/affiliations/relationships`;

    let params: HttpParams = new HttpParams();
    if (typeIDs) {
      params = params.set('affilTypeIDs', typeIDs.join(','));
    }

    return this.get(endpoint, params).pipe(map(
      data => {
        data = plainToClass(AffiliationRelationship, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  saveAffiliations(affiliations: Affiliation[], id: number): Observable<Affiliation[]> {
    const endpoint = `${this.baseUrl}/entities/${id}/affiliations`;
    return this.put(endpoint, affiliations).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  createAffiliationsSocket(entityID: number) {
    this.affiliationsWebSocket = createThunderWebSocket(this.affiliationsWebSocket, `entities/${entityID}/affiliations`, this.broadcastChannelService);
    return this.affiliationsWebSocket;
  }

  closeAffiliationsSocket() {
    if (this.affiliationsWebSocket) {
      this.affiliationsWebSocket.close();
    }
  }

  savePositionGrouping(position: Position, id: number) {
    const endpoint = `${this.baseUrl}/people/${id}/positionGroup`;

    return this.post(endpoint, position).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getTeamHistoryMetrics(id: number) {
    const endpoint = `${this.baseUrl}/teams/${id}/metrics/measures?aggregation=teamSeason`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getLeagueHistoryMetrics(id: number) {
    const endpoint = `${this.baseUrl}/leagues/${id}/metrics/measures?aggregation=leagueSeason`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getOwnershipProfile(userId: number): Observable<OwnershipProfile> {
    const endpoint = `${this.baseUrl}/people/${userId}/ownershipProfile`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  saveOwnershipProfile(userId: number, ownershipProfile: OwnershipProfile): Observable<OwnershipProfile> {
    const endpoint = `${this.baseUrl}/people/${userId}/ownershipProfile`;

    return this.put(endpoint, ownershipProfile).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  saveOwnershipProfileHeadline(ownershipHeadline: OwnershipHeadline, userId?: number): Observable<OwnershipHeadline[]> {
    let endpoint: string;
    let method: any;

    if (ownershipHeadline.id) {
      endpoint = `${this.baseUrl}/ownershipHeadlines/${ownershipHeadline.id}`;
      method = this.put.bind(this);
    } else {
      endpoint = `${this.baseUrl}/people/${userId}/ownershipHeadlines`;
      method = this.post.bind(this);
    }

    return method(endpoint, ownershipHeadline).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  deleteOwnershipProfileHeadline(id: number): Observable<void> {
    const endpoint = `${this.baseUrl}/ownershipHeadlines/${id}`;

    return this.delete(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  uploadPersonalHeadshot(formData, entityID) {
    const endpoint = `${this.baseUrl}/people/${entityID}/headshot`;
    return this.post(endpoint, formData).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  uploadTeamLogo(formData, entityID) {
    const endpoint = `${this.baseUrl}/teams/${entityID}/logo`;
    return this.post(endpoint, formData).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  uploadResume(formData, entityID) {
    const endpoint = `${this.baseUrl}/people/${entityID}/resume`;
    return this.post(endpoint, formData).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  activateEntity(entityID: number): Observable<object[]> {
    const endpoint = `${this.baseUrl}/entities/${entityID}/activate`;
    return this.put(endpoint, {entityID: entityID}).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getDraftPersons() {
    const endpoint = `${this.baseUrl}/entities/draft`;

    let params: HttpParams = new HttpParams();
    params = params.set('entityTypeIds', EntityTypeIDMap[EntityType.PERSON]);

    return this.get(endpoint, params).pipe(map(
      data => {
        data = plainToClass(DraftPerson, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getDraftTeams() {
    const endpoint = `${this.baseUrl}/entities/draft`;

    let params: HttpParams = new HttpParams();
    params = params.set('entityTypeIds', EntityTypeIDMap[EntityType.TEAM]);

    return this.get(endpoint, params).pipe(map(
      data => {
        data = plainToClass(DraftTeam, data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getLineupRowCorrelations(entityID: number, filters: any, rowData: any): Observable<any> {
    const endpoint = `${this.baseUrl}/teams/${entityID}/lineups/metrics/correlations`;
    let params: HttpParams = new HttpParams();
    params = params
      .set('seasons', filters.seasons.join(','))
      .set('gameTypes', filters.gameTypes.join(','))
      .set('lineupSize', filters.lineupSize)
      .set('sublineup', rowData.sublineup)
      .set('rowPossessions', rowData.possessions)

    if (filters.minGame) params = params.set('minGame', filters.minGame);
    if (filters.maxGame) params = params.set('maxGame', filters.maxGame);
    if (filters.minDate) params = params.set('minDate', filters.minDate);
    if (filters.maxDate) params = params.set('maxDate', filters.maxDate);
    if (filters.onCourtIDs) params = params.set('onCourtIDs', filters.onCourtIDs);
    if (filters.onCourtOptions) params = params.set('onCourtOptions', filters.onCourtOptions);
    if (filters.onCourtPositions) params = params.set('onCourtPositions', filters.onCourtPositions);
    if (filters.allLineupsOption) params = params.set('allLineupsOption', filters.allLineupsOption);

    return this.get(endpoint, params).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getOfficiatingDataCoverage() {
    const endpoint = `${this.baseUrl}/leagues/officiating`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  uploadOfficiatingData(file) {
    const endpoint = `${this.baseUrl}/leagues/officiating`;

    return this.post(endpoint, file).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }
}
